import React, {useEffect, useState} from 'react';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DoneIcon from '@material-ui/icons/Done';
import LoopIcon from '@material-ui/icons/Loop';
import Fab from "@material-ui/core/Fab";
import TextField from '@material-ui/core/TextField';
import './importList.scss'
import SingletoneStoreClass from "../../../store/store";
import Toast from '../../toast/toast'
import db from '../../../store/dbManager';

const store = SingletoneStoreClass.getInstance();


const ImportList = (props)=>{

    const {updateGroceryListFromStore, addLiveSharingFromStore, groceryList} = props;

    const [open, setOpen] = React.useState(false);
    const [listId, setListId] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [listFromCloud, setListFromCloud] = React.useState(null);
    const [makeToast, setMakeToast] = React.useState({toast: false, text: '', severity: ''});

    useEffect(() => {
        if(!listFromCloud) return;

    }, [listFromCloud]);


    const onListIdChanged = (e)=>{
        const id = e.target.value;
        setListId(id);
    }

    const onImportListClicked = ()=>{
        setLoading(true);
        const liveSharingId = `id_${listId}`
        if(store.getLiveSharing()[liveSharingId]){
            console.info(`live sharing (${liveSharingId}) already exist!`);
            setMakeToast({toast: true, text: "רשימה כבר קיימת!", severity: 'warning'});
            setLoading(false);
            return;
        }
        const ref = db.getRef({id: liveSharingId});
        ref.on('value', (snapshot) => {
            const list = snapshot.val();
            if(list){
                setMakeToast({toast: true, text: "טוען רשימה...", severity: 'success'});
                setListFromCloud(list);

                const lastGroceryList = groceryList;
                lastGroceryList.push({id: liveSharingId, name: `רשימה משותפת -${listId}`  });
                store.addLiveSharing(liveSharingId);
                store.saveGroceryListItems(lastGroceryList, liveSharingId, list.groceries);
                addLiveSharingFromStore();
                updateGroceryListFromStore();
                setListId(0);
                setOpen(false);

                setTimeout(()=>{
                    setMakeToast({toast: false, text: "", severity: ''});
                },3000)
            }
            else{
                setMakeToast({toast: true, text: 'שם רשימה שגוי', severity: 'error'});
            }
            setLoading(false);
        });
    }

    return <div>
        <Fab className={`import-list-button-container ${open ? 'open' : ''}`} aria-label="add">
            {open ?
                <div className={'import-list-container-open-mode'}>
                    <div>
                        <div className={'import-list-text'}>לייבא רשימה לפי מספר מזהה הקלידו כאן:</div>
                        <TextField
                            className={'import-list-text-field'}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            value={listId ? listId : ''}
                            onChange={onListIdChanged}
                        />
                    </div>
                    {listId.length >= 1 ? (loading ? <LoopIcon/> : <DoneIcon onClick={onImportListClicked}/>) : <ChevronRightIcon onClick={()=>setOpen(!open)}/>}
                </div>
                :
                <ChevronLeftIcon onClick={()=>setOpen(!open)}/>}
            {makeToast.text ? <Toast addGrocery={makeToast} text={makeToast.text} severity={makeToast.severity}/> : null}
        </Fab>
    </div>
}

export default ImportList