import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from "@material-ui/core/Typography";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import GetAppIcon from '@material-ui/icons/GetApp';
import LocalGroceryStoreIcon from '@material-ui/icons/LocalGroceryStore';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import MobileScreenShareIcon from '@material-ui/icons/MobileScreenShare';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import FeedbackIcon from '@material-ui/icons/Feedback';

import MenuIcon from '@material-ui/icons/Menu';
import './menu.scss';
import config from "../../../config/config";

import SingletoneStoreClass from "../../../store/store";

const store = SingletoneStoreClass.getInstance();

const {downloadJson, sendFeedback, shareApp} = require('../../../utils');


export default function MenuBar() {
    const [menuEl, setAnchorEl] = React.useState(null);
    const [clicks, setClicks] = React.useState({lastClick: new Date(), counter: 0});

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const _handleClose = () => {
        setAnchorEl(null);
    };

    const onDownloadClick = () => {
        downloadJson(JSON.stringify(store.getDictionary()), 'categories.json', 'application/json');
        _handleClose();
    };

    const onDeleteAllManualGroceriesClicked = () => {
        store.deleteAllManualGroceries();
        _handleClose();
    };


    const onExistApp = ()=>{
        store.exit();
        window.location.href = `${window.location.origin}/logIn`

    }

    const onVersionClicked = ()=>{
        const currentTime = new Date();
        if((currentTime - clicks.lastClick) > 2000){
            setClicks({lastClick: new Date(), counter: 0});
        }else{
            setClicks({lastClick: new Date(), counter: clicks.counter + 1});
        }
        if(clicks.counter > 5){
            console.log("BOOM")  ;
            onDownloadClick();
        }
    }



    return (
        <React.Fragment>
            <MenuIcon onClick={handleClick}/>
            <Menu
                className={'menu-bar'}
                id="simple-menu"
                anchorEl={menuEl}
                keepMounted
                open={Boolean(menuEl)}
                onClose={_handleClose}
            >
                <MenuItem className={'menu-item-container'} onClick={()=>shareApp(store.getLogicalName())}>
                    <MobileScreenShareIcon>
                        <GetAppIcon fontSize="small"/>
                    </MobileScreenShareIcon>
                    <Typography className={'menu-text'} variant="inherit">שתף אפליקצייה עם חברים!</Typography>
                </MenuItem>
                <MenuItem className={'menu-item-container'} onClick={sendFeedback}>
                    <FeedbackIcon fontSize="small"/>
                    <Typography className={'menu-text'} variant="inherit">שלח פידבק</Typography>
                </MenuItem>
                {/*<MenuItem className={'menu-item-container'} onClick={onDeleteAllManualGroceriesClicked}>*/}
                    {/*<ListItemIcon>*/}
                    {/*    <DeleteForeverIcon fontSize="small"/>*/}
                    {/*</ListItemIcon>*/}
                    {/*<Typography className={'menu-text'} variant="inherit">מחק פריטים שנוספו באופן ידני</Typography>*/}
                {/*</MenuItem>*/}
                <MenuItem className={'menu-item-container menu-item-container-exit'} onClick={onExistApp}>
                    <ListItemIcon>
                        <PowerSettingsNewIcon fontSize="small"/>
                    </ListItemIcon>
                    <Typography className={'menu-text'} variant="inherit">התנתק</Typography>
                </MenuItem>
                <MenuItem className={'menu-item-container'} disabled={false} selected onClick={onVersionClicked}>
                    <ListItemIcon>
                        <LocalGroceryStoreIcon fontSize="small"/>
                    </ListItemIcon>
                    <Typography className={'menu-text'} variant="inherit">גרסא: {config.version} </Typography>
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
}