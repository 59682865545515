import CATEGORIES from './categories.json';
import listaIconColorIcon from '../icons/liveSharingcircleOn.svg';
import superGlacenerIcon from '../icons/super-glacener.jpg';
import benShushanIcon from '../icons/ben-shushan.jpg';
import livnoniIcon from '../icons/livnoni.jpg';
import nofimIcon from '../icons/nofim.jpg';
import beniKamil from '../icons/beni-kamil.jpg';
import aliza from '../icons/aliza.jpg';
import hindic from '../icons/hindic.jpg';
import icon_8114 from '../icons/8114.jpg';
import alonIcon from '../icons/alon.jpg';
import barIlanIcon from '../icons/bar-ilan.jpg';
import aleyZahav from '../icons/aley-zahav.png';
import limor_30Icon from '../icons/limor_30.jpg';
import ori_naama_Icon from '../icons/ori_naama.jpg';
import bibiIcon from '../icons/bibi.jpg';
import bneiAkiva from '../icons/bnei_akiva.png';
import ezra from '../icons/Logo-ezra.jpg';
import shomron from '../icons/shomron.png';
import atlas from '../icons/atlas.png';


export default {
    CATEGORIES,
    STORES: [
        {logicalName: 'welcome', name: 'ניהול הרשימות החכם שלך!' },
        {logicalName: 'livnoni', name: 'משפחת לבנוני' , title: 'רשימת הקניות שלי!', subTitle: 'מה אני צריך יותר מזה ?', logo: livnoniIcon},
        {logicalName: 'ben-shushan', name: 'משפחת בן שושן' , title: 'המכולת השכונתית של לשם!', subTitle: 'המחיר זול הלקוח יקר', logo: benShushanIcon},
        {logicalName: 'superzolnofim', name: 'סופר זול נופים' , title: 'המכולת של היישוב נופים', subTitle: 'המחיר זול הלקוח יקר', logo: nofimIcon},
        {logicalName: 'super-ashli', name: 'סופר אשלי' , title: 'המכולת של עלי זהב - לשם', subTitle: 'המחיר זול הלקוח יקר', logo: aleyZahav, location: { latitude: 32.0725962, longitude: 35.0643172 }, ownerPhone: '+972507520650', infoLink: 'https://docs.google.com/document/d/13IJ7vzj3mtwdhlZZbzHQc774DC5oEgM2sRH-1bXxQ1g/export?format=pdf'},
        // {logicalName: 'super-glacener', name: 'סופר גלסנר' , title: 'רשת הסופרים של השומרון', subTitle: 'המחיר זול הלקוח יקר!', logo: superGlacenerIcon},
        {logicalName: 'beni-kamil', name: 'בני קמיל' , title: 'הקבלן שלך!', subTitle: 'מקצוענות לפני הכל', logo: beniKamil},
        // {logicalName: 'turgeman', name: 'משפחת תורג׳מן' , title: 'אוכל קדימה אוכל', subTitle: 'רשימת הקניות של האצולה', logo: aliza},
        // {logicalName: 'hindik', name: 'משפחת הינדיק' , title: 'האפליקציה של ערן וגנית!', subTitle: 'מכאן האוכל הטעים מתחיל', logo: hindic},
        {logicalName: '8114', name: 'גדוד 8114' , title: 'ניהול רשימות ציוד!', subTitle: '', logo: icon_8114},
        {logicalName: 'alon', name: 'אלון חן ציון' , title: 'המורה לשל״ח הטוב בעולם!', subTitle: 'נהלו את הרשימות שלכם ולא תשכחו כלום', logo: alonIcon},
        {logicalName: 'bar-ilan', name: 'משפחת בר אילן' , title: 'משפחה של חכמים', subTitle: 'נהלו את הרשימות שלכם ולא תשכחו כלום', logo: barIlanIcon},
        // {logicalName: 'limor_30', name: 'לימור בת 30 !' , logo: limor_30Icon},
        // {logicalName: 'ori_naama', name: 'אפליקציית הרשימות של אורי ונעמה אמיר !' , logo: ori_naama_Icon},
        {logicalName: 'tsahi_livnoni', name: 'אפליקציית הרשימות של צחי לבנוני !', logo: bibiIcon},
        {logicalName: 'maayan_turgeman', name: 'בני עקיבא בארות יצחק', logo: bneiAkiva},
        {logicalName: 'ezra_rehovot', name: 'עזרא רחובות', logo: ezra},
        {logicalName: 'shomrin', name: 'מחשוב - מועצה אזורית שומרון', logo: shomron},
        {logicalName: 'atlas', name: 'אטלס', logo: atlas}
    ],
    version: '6.2.20',
    feedbackPhone: '+972543007218'
}

//to add logo: https://www.reduceimages.com/ (jpg, 70% 70x70)