import React, {useEffect, useState} from 'react';

import './landingPage.scss';
import animation from './animation.gif';


export default function LandingPage(props) {
    const { setShowLandingPage } = props;
    const [showTitle, setShowTitle] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setShowTitle(true)
        }, 1500)

    }, []);

    return <div className={'landing-page-container'} onClick={()=>setShowLandingPage(false)}>
        <img className={'landing-page-animation'} src={animation} alt="loading..." />
        {showTitle ? <div className={'landing-page-tile'}>הרשימה החכמה שלך</div> : null}
    </div>

}