// Your web app's Firebase configuration
import firebase from "firebase";

const firebaseConfig = {
    apiKey: "AIzaSyBEF1MOVfQltXbzvt10fkz1JzSYetuQtm4",
    authDomain: "grocery-manager-92a05.firebaseapp.com",
    databaseURL: "https://grocery-manager-92a05.firebaseio.com",
    projectId: "grocery-manager-92a05",
    storageBucket: "grocery-manager-92a05.appspot.com",
    messagingSenderId: "720030196465",
    appId: "1:720030196465:web:262ab85d289ba2c22e7f50",
    measurementId: "G-3ZGDYKZTRG"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics();
const database = firebase.database();
const auth = firebase.auth();


export default {analytics, database, auth};