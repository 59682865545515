import React, {useEffect, useState} from 'react';
import './itemList.css';
import CategoryContainer from '../../components/categoryContainer/categoryContainer'
import Header from './header/header'
import SingletoneStoreClass from "../../store/store";
import Divider from "@material-ui/core/Divider";
import Input from './input/input';
import Toast from '../../components/toast/toast';
import Navigator from '../../components/navigator/navigator';
import firebase from '../../firebase/firebase';
import db from "../../store/dbManager";
const {analytics} = firebase;
const store = SingletoneStoreClass.getInstance();

const ItemList = (props)=>{
    const { id } = props;

    const [inputText, setInputText] = useState('');
    const [addGrocery, setAddGrocery] = useState(0);
    const [GroceriesCounter, setGroceriesCounter] = useState(0);
    const [groceries, setGroceries] = useState(store.getGroceries({id}) || {});
    const [selectedGroceries, setSelectedGroceries] = useState(store.getSelectedGroceries({id}));
    const [LiveSharing, setLiveSharing] = useState(store.getLiveSharing());
    const [liveSharingLastUpdated, setLiveSharingLastUpdated] = useState(null);
    const [forceUpdate, setForceUpdate] = useState(null);
    const [todoMode, setTodoMode] = useState(false);
    const [firstRender, setFirstRender] = useState(true);

    setTimeout(()=>setFirstRender(false), 1000)


    useEffect(() => {
        if(!LiveSharing[id]) return;
        const ref = db.getRef({id});

        ref.on('value', (snapshot) => {
            console.log('---[ItemList]snapshot.key=',snapshot.key);
            console.log('---[ItemList]snapshot.val()=',snapshot.val());
            const LiveSharingId = snapshot.key;

            console.info(`updating list from cloud id= ${LiveSharingId}`);
            if(!snapshot.val().groceries) return; //got empty list from cloud - do nothing
            store.updateBulkOfGroceries({id: LiveSharingId, groceries: snapshot.val().groceries});
            setGroceries(snapshot.val().groceries);
            setLiveSharingLastUpdated(snapshot.val().lastUpdate);
        });

        return function cleanup() {
            console.log(`[ItemList] unsubscribe ref: ${ref}`);
            ref.off();
        };
    }, [forceUpdate]);

    useEffect(() => {
        setGroceriesCounter(Object.keys(groceries).reduce((sum, cur) => sum + Object.keys(groceries[cur]).length, 0));
    }, [groceries]);

    useEffect(() => {
        onChooseButtonClicked()
    }, [addGrocery]);

    const addManuallyNewGrocery = (text) => {
        if (!text ) return;
        if (text.trim() === '' ) return;

        const [category, code] = store.classify(text);
        analytics.logEvent('selected', {inputText: `${text}-${category}`});


        if(category === 'כללי'){
            analytics.logEvent('unClassifyWord', {inputText: text});
        }

        store.updateGroceries({id, category, code, value: true});

        setGroceries({...store.getGroceries({id})});
        setInputText('');
        setAddGrocery(addGrocery + 1);
    }

    const onChooseButtonClicked = () => {
        if (!inputText ) return;
        if (inputText.trim() === '' ) return;

        const [category, code] = store.classify(inputText);
        analytics.logEvent('selected', {inputText: `${inputText}-${category}`});

        if(category === 'כללי'){
            analytics.logEvent('unClassifyWord', {inputText});
        }

        store.updateGroceries({id, category, code, value: true});

        setGroceries({...store.getGroceries({id})});
        setInputText('');
        setAddGrocery(addGrocery + 1);
    };

    const onDeleteGrocery = ({category, code}) => {
        store.updateGroceries({id, category, code, value: false});
        setGroceries({...store.getGroceries({id})});
    };

    const onBackButtonClicked = () => {
        try {
            const lastEvent = store.getLastPastEvents();
            if (lastEvent) {
                store.updateGroceries({
                    id,
                    category: lastEvent.category,
                    code: lastEvent.code,
                    value: !lastEvent.value,
                    updatePastEvents: false
                });
                setGroceries({...store.getGroceries({id})});
            }
        } catch (e) {
            console.error('got error while onBacButtonClicked:', e)
        }
    };

    const onSelectAll = (status)=>{
        store.selectAll({id, status});
        setSelectedGroceries({...store.getSelectedGroceries({id})});
    };

    const deleteSelected = () => {
        store.deleteAll({id});
        setGroceries({...store.getGroceries({id})});
        setSelectedGroceries({...store.getSelectedGroceries({id})});
    };

    // // for debugging only!!!
    // useEffect(() => {
    //     console.log("----itemList state: ", {inputText, groceries, selectedGroceries})
    // }, [inputText, groceries, selectedGroceries]);



    return (
        <div className={'my-list-container'}>
            <Navigator onBacButtonClicked={onBackButtonClicked} id={id} forceUpdate={setForceUpdate} todoMode={todoMode} setTodoMode={setTodoMode}/>
            <div className="app-container">
                <Header GroceriesCounter={GroceriesCounter}
                        onDeleteSelectedClicked={deleteSelected}
                        onSelectAll={onSelectAll}
                        selectedGroceries={selectedGroceries}
                        id={id}
                        isLiveSharing={LiveSharing[id]}
                        liveSharingLastUpdated={liveSharingLastUpdated}
                        onBackButtonClicked={onBackButtonClicked}/>
                <Input
                    inputText={inputText}
                    setInputText={setInputText}
                    addManuallyNewGrocery={addManuallyNewGrocery}
                    onChooseButtonClicked={onChooseButtonClicked}
                    setAddGrocery={setAddGrocery}
                    addGrocery={addGrocery}
                />
                <div className={'groceries-items-container'}>
                    {Object.keys(groceries).map(category =>{
                        return <CategoryContainer id={id}
                                                 categoryName={category}
                                                 groceriesOfCategory={groceries[category]}
                                                 key={`category-container-${category}`}
                                                 onDeleteGrocery={onDeleteGrocery}
                                                 selectedGroceries={selectedGroceries}
                                                 setSelectedGroceries={setSelectedGroceries}
                                                 groceries={groceries}
                                                 todoMode={todoMode}
                                                  firstRender={firstRender}
                                                 setGroceries={setGroceries}/>})}
                    {GroceriesCounter > 0 ? <Divider/> : null}
                    <Toast addGrocery={addGrocery} text={'המוצר נוסף בהצלחה'}/>
                </div>

            </div>
        </div>
    );

}

export default ItemList;