import React, {useEffect, useState} from 'react';
import './App.scss';
import {create} from 'jss';
import rtl from 'jss-rtl';

import ItemList from '../src/components/itemList/itemList';
import StartPage from '../src/components/startPage/startPage';
import MyGrocery from '../src/components/myGrocery/myGrocery';
import Welcome from '../src/components/myGrocery/welcome';
import AppTitle from '../src/components/appTitle/appTitle';
import LandingPage from '../src/components/landingPage/landingPage';
import firebase from './firebase/firebase';


import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import {jssPreset, StylesProvider} from "@material-ui/core/styles";
import SingletoneStoreClass from "./store/store";
import config from './config/config';

const logicalNames = config.STORES.map(a => a.logicalName);

const store = SingletoneStoreClass.getInstance();


// Configure JSS
const jss = create({plugins: [...jssPreset().plugins, rtl()]});

firebase.auth.signInAnonymously()
    .then((e) => {
        // Signed in..
        console.log("successfully log in.")
    })
    .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        // ...
    });

const App = () => {
    const [showLandingPage, setShowLandingPage] = useState(true); //set false for develop

    useEffect(() => {
        setTimeout(() => {
            setShowLandingPage(false)
        }, 1000 * 4)

    }, []);

    return (
        <StylesProvider jss={jss}>
            {showLandingPage ?
                <LandingPage setShowLandingPage={setShowLandingPage}/> :
                <Router>
                    <AppTitle/>
                    <div>
                        <Switch>
                            <Route path="/logIn">
                                <StartPage/>
                            </Route>
                            <Route
                                path="/:logicalName/list/:id" render={({match}) => (<ItemList id={match.params.id}/>)}>
                            </Route>
                            <Route path="/:logicalName/list/">
                                <MyGrocery/>
                            </Route>
                            <Route path="/:logicalName" render={({match}) => {
                                const {logicalName} = match.params;
                                if (logicalNames.includes(logicalName)) store.setLogicalName(logicalName);
                                else store.setLogicalName('welcome');
                                return <Welcome/>
                            }}>
                            </Route>
                            <Route path={'/'}>
                                {store.getLogicalName() ? <Redirect to={`/${store.getLogicalName()}`}/> : <Redirect to={`/welcome`}/>}
                            </Route>
                        </Switch>
                    </div>
                </Router>
            }


        </StylesProvider>
    );
}

export default App;
