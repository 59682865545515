import React from 'react';

import './appTitle.scss'
import { useHistory, useLocation } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ListaMainLogo from  '../../icons/listaMainLogo.svg'
import SingletoneStoreClass from "../../store/store";
const store = SingletoneStoreClass.getInstance();


const AppTitle = ()=>{
    const history = useHistory();
    const location = useLocation();


    return <React.Fragment>
        <div className={'my-grocery-app-title'} onClick={()=>{
            if(!location.pathname.endsWith('/list')) history.push(`/${store.getLogicalName()}`); //don't do nothing if list page is open
        }
        }>
            <img className={`lista-main-icon`} src={ListaMainLogo} alt="React Logo" />
        </div>
        {!location.pathname.endsWith(store.getLogicalName()) ? <ArrowBackIosIcon className={'list-move-back'} onClick={history.goBack}/> : null}
    </React.Fragment>
}

export default AppTitle;
