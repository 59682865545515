import React, {useEffect, useState} from 'react';
import './header.scss'
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import Snackbar from "@material-ui/core/Snackbar";
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import Checkbox from "@material-ui/core/Checkbox";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';


import SingletoneStoreClass from "../../../store/store";
import ReplayIcon from "@material-ui/icons/Replay";
import IconButton from "@material-ui/core/IconButton";
const store = SingletoneStoreClass.getInstance();


const Header = (props) => {
    const {GroceriesCounter, onDeleteSelectedClicked, onSelectAll, selectedGroceries, isLiveSharing, id, onBackButtonClicked, liveSharingLastUpdated} = props;
    const [open, setOpen] = React.useState(false);
    const [markAll, setMarkAll] = React.useState(false);
    const [pastEvents, setPastEvents] = React.useState(store.getPastEvents());


    const handleDeleteIconClicked = () => {
        if(GroceriesCounter === 0) return;
        if(getBumOfSelectedItems() === 0) return;
        setOpen(true);
    };

    const handleDeleteAllClose = (event, reason) => {
        if (reason === 'clickaway') {
            handleNoClicked();
        }
    };

    const handleOkClicked = () => {
        setOpen(false);
        onDeleteSelectedClicked();
        setMarkAll(false);
    };

    const handleNoClicked = () => {
        setOpen(false);
    };

    const onMarkAllClicked = () => {
        setMarkAll(!markAll);
        onSelectAll(!markAll);
    };

    useEffect(() => {
        if(GroceriesCounter - getBumOfSelectedItems() === 1){
            setMarkAll(false);
        }
    }, [selectedGroceries]);

    const getBumOfSelectedItems = () => {
        let counter = 0;
        Object.keys(selectedGroceries).forEach(category => {
            Object.keys(selectedGroceries[category]).forEach(code => {
                if (selectedGroceries[category][code]) counter++;
            })
        });
        return counter;
    };

    const calcLastUpdateText = (lastUpdated)=> {
        const date = new Date(lastUpdated);
        let minutes = date.getMinutes();
        if(minutes <10) minutes = `0${minutes}`;
        const s = `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} ${date.getHours()}:${minutes}`.toString();
        if(s.includes('NaN')) return lastUpdated; //iphones get Nan from some reason
        return s
    }

    return (
        <div className={"header-container"}>
            <div className={'header-container-right'}>
                <Checkbox
                    disabled={GroceriesCounter === 0}
                    className={'mark-all-button'}
                    checked={markAll}
                    onChange={onMarkAllClicked}
                    inputProps={{'aria-label': 'primary checkbox'}}
                />
                <DeleteIcon
                    className={`delete-all-button ${getBumOfSelectedItems() === 0 ? 'disabled' : ''}`}
                    onClick={handleDeleteIconClicked}
                />
                <IconButton className={'set-back-button'} edge="start" color="inherit" aria-label="menu" disabled={pastEvents.length === 0} onClick={()=>{
                    onBackButtonClicked();
                    setPastEvents(store.getPastEvents());
                }}>
                    <ReplayIcon fontSize="large"/>
                </IconButton>
            </div>


            <div className={'header-container-left'}>
                {liveSharingLastUpdated ? <div className={'last-update-list-container'}>
                    <div>שמירה אוטומטית</div>
                    <div>&nbsp;</div>
                    <div className={'last-update-list-container-date'}>{calcLastUpdateText(liveSharingLastUpdated)}</div>
                </div>  : null}
            </div>

            <Snackbar className={'are-u-sure-container'}
                      anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                      }}
                      open={open}
                      onClose={handleDeleteAllClose}
                      message={`האם אתה בטוח שברצונך למחוק ${getBumOfSelectedItems()} מוצרים?`}
                      action={
                          <React.Fragment>
                              <Button
                                  onClick={handleNoClicked}
                                  variant="contained"
                                  color="primary"
                                  startIcon={<CloseIcon/>}
                              >
                                  לא
                              </Button>
                              <Button
                                  onClick={handleOkClicked}
                                  variant="contained"
                                  color="primary"
                                  startIcon={<CheckIcon/>}
                              >
                                  כן
                              </Button>
                          </React.Fragment>
                      }
            />
        </div>
    )
};

export default Header