import React, {useEffect, useState} from 'react';

import './welcome.scss'
import SingletoneStoreClass from "../../store/store";
import config from '../../config/config';
import { useHistory } from 'react-router-dom';
import ListIcon from '@material-ui/icons/FormatListBulletedRounded';
import Button from '@material-ui/core/Button';
import FeedbackIcon from '@material-ui/icons/Feedback';
import ShareIcon from '@material-ui/icons/Share';

import { ReactComponent as HelpIcon } from '../../icons/help.svg';
import { ReactComponent as WazeIcon } from '../../icons/waze.svg';

import ShareModal from "../shareModal/shareModal";
import {SvgIcon} from "@material-ui/core";

const store = SingletoneStoreClass.getInstance();

const {sendFeedback, shareApp} = require('../../utils');


export default function Welcome(){
    const logicalName = store.getLogicalName();
    const storeInfo = config.STORES.find(s=>s.logicalName === logicalName);

    const [groceryList, setGroceryList] = React.useState(store.getGroceryListItems());
    const [shareMode,] = useState(store.isShareMode());

    const updateGroceryListFromStore = ()=>{
        setGroceryList(JSON.parse(JSON.stringify(store.getGroceryListItems())));
    }

    const history = useHistory();
    const addNewGroceryList = (name = 'הרשימה שלי') => {
        const lastGroceryList = groceryList;
        const groceryId = `id_${new Date().getTime()}`;
        lastGroceryList.push({id: groceryId, name});
        store.saveGroceryListItems(lastGroceryList);
        updateGroceryListFromStore();
        return groceryId;
    }

    return <div className={'welcome-grocery-container'}>
        {shareMode ? <ShareModal addNewGroceryList={addNewGroceryList}/> : null}
        <div className={'style-background-top'}></div>
        <div className={'my-grocery-header'}>
                <div className={'my-grocery-welcome'}>
                ברוכים הבאים
            </div>
            <div className={'my-grocery-name'}>
                {storeInfo.name}
            </div>
            {storeInfo.logo ? <div className={'my-grocery-logo-container'}>
                <img src={storeInfo.logo} alt="fireSpot"/>
            </div> : null}
            {storeInfo.infoLink ?
                <div className={'grocery-shop-info'}>
                    <Button variant="contained" onClick={()=>window.location = storeInfo.infoLink}>למבצעים והנחות שלנו</Button>
                </div>
                : null}
            <div className={'my-grocery-bottom-buttons'}>
                {storeInfo.ownerPhone ? <div className={'my-grocery-logo-msg-to-grocery-manager'}>
                    <button className={'circle-button'}>
                        <SvgIcon
                            component={HelpIcon}
                            viewBox="0 0 600 476.6"
                            onClick={()=>{
                            const msg = `שלום בעל מכולת : %0A`;
                            window.location.href = `whatsapp://send?text=${msg}&phone=${storeInfo.ownerPhone}`;
                        }}/>
                    </button>
                    <div className={'my-grocery-logo-text'}>
                        שאל את הצוות
                    </div>
                </div> : (storeInfo.logicalName === 'welcome' ? <div className={'my-grocery-logo-msg-to-grocery-manager'}>
                    <button className={'circle-button'}>
                       <ShareIcon onClick={()=>shareApp(store.getLogicalName())}/>
                    </button>
                    <div className={'my-grocery-logo-text'}>
                        שתף עם חברים
                    </div>
                </div> : null) }
                <div className={'my-grocery-logo-feedback'}
                     onClick={()=>{
                         history.push(`/${store.getLogicalName()}/list`)
                     }}>
                    <button className={'circle-button large main-button'}>
                        <ListIcon/>
                    </button>
                    <div className={'my-grocery-logo-text main'}>
                        לרשימות שלי
                    </div>
                </div>
                {storeInfo.location ? <div className={'my-grocery-logo-location'}>
                    <button className={'circle-button'}>
                        <SvgIcon
                            component={WazeIcon}
                            viewBox="0 0 600 476.6"
                            onClick={()=>{
                            window.location.href = `geo:${storeInfo.location.latitude},${storeInfo.location.longitude}`;
                        }}/>
                    </button>
                    <div className={'my-grocery-logo-text'}>
                        ניווט לחנות
                    </div>
                </div> : (storeInfo.logicalName === 'welcome' ?
                    <div className={'my-grocery-logo-location'} onClick={sendFeedback}>
                        <button className={'circle-button'}>
                            <FeedbackIcon/>
                        </button>
                        <div className={'my-grocery-logo-text'}>
                            פידבק
                        </div>
                    </div> : null)}
            </div>

        </div>
    </div>
}