import firebase from '../firebase/firebase';
// firebase database reference API: https://firebase.google.com/docs/reference/node/firebase.database.Reference#set
const refsMap = {};
const DbManager = {
    save: async ({id, groceries})=>{
        const listRef = firebase.database.ref('listOfGroceries');
        try{
            await listRef.update({[id]: { // use set it we want to delete from DB
                groceries,
                lastUpdate: new Date().toLocaleString()
            }});
        }catch (e){
            console.error(`firebase save with error:`,e);
        }
        console.log("pushed");
    },
    subscribe: ({id})=>{
        const ref = firebase.database.ref(`listOfGroceries/${id}`);
        // ref.on('value', (snapshot) => {
        //     console.log('---snapshot.key=',snapshot.key);
        //     console.log('---snapshot.val()=',snapshot.val());
        // });
        refsMap[id] = ref;
    },
    unsubscribe: ({id})=>{
        if(!refsMap[id]) return;
        refsMap[id].off();
        delete refsMap[id];
    },
    getRefsMap: ()=> refsMap,
    getRef: ({id}) => firebase.database.ref(`listOfGroceries/${id}`)
}

export default DbManager;