import React, {useEffect, useState,} from 'react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import './groceryItemList.scss'
import { useHistory } from 'react-router-dom';
import SingletoneStoreClass from "../../../store/store";
import LiveSharingModal from '../liveSharingModal/liveSharingModal';
import Snackbar from "@material-ui/core/Snackbar";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import ShareIcon from '@material-ui/icons/Share';

const store = SingletoneStoreClass.getInstance();

export default function GroceryItemList(props){

    const { name, totalItems = 0, id, deleteGroceryListItem, editGroceryListItemName, isLiveSharing, updateLiveSharingFromStore, isLastList } = props;

    const [editMode, setEditMode] = React.useState(props.editMode || false);
    const [liveSharingModalOpen, setLiveSharingModalOpen] = React.useState(false);
    const [showAreYouSureModal, setShowAreYouSureModal] = React.useState(false);

    const history = useHistory();

    const onNameFocusOut = (e)=>{
        const newName = e.target.textContent.trim();
        editGroceryListItemName({id, newName});
        setEditMode(false);
    }

    const blockOnClick = (e)=>{
        if (!e) var e = window.event;
        e.cancelBubble = true;
        if (e.stopPropagation) e.stopPropagation();
    }

    const onItemClicked = (event)=>{
        if(editMode) return;
        history.push(`/${store.getLogicalName()}/list/${id}`);
    }

    const focusEditMode = ()=>{
        setTimeout(()=>{
            const editTextElement = document.getElementById(`title_${id}`);
            if(editTextElement){
                editTextElement.scrollIntoView();
                editTextElement.focus();
                editTextElement.click();
                document.execCommand('selectAll',false,null);
            }
        }, 1);
    }

    if(editMode) focusEditMode();

    const makeListToBeLiveSharing = async ()=>{
        await store.createLiveSharing(id);
        store.addLiveSharing(id);
        updateLiveSharingFromStore();
    }

    const deleteGroceryList = ()=>{
        deleteGroceryListItem(id);
        store.removeLiveSharing(id);
        updateLiveSharingFromStore();
        blockOnClick();
    }

    return <React.Fragment>
        <div className={'grocery-item'}>
            <LiveSharingModal open={liveSharingModalOpen} setLiveSharingModalOpen={setLiveSharingModalOpen} id={id} name={name} makeListToBeLiveSharing={makeListToBeLiveSharing} isLiveSharing={isLiveSharing}/>
            <div className={'grocery-item-right'}>
                <div className={'delete-item-button'} onClick={()=>{
                    setShowAreYouSureModal(true);
                    // deleteGroceryList();
                }}><DeleteForeverIcon/></div>
                <div className={`live-sharing-item-button live-sharing-${isLiveSharing ? 'on' : 'off'}`} onClick={async ()=>{setLiveSharingModalOpen(true)}}>
                    <ShareIcon/>
                </div>
                <div>
                    <EditIcon onClick={()=>{
                        setEditMode(true);
                        focusEditMode();
                    }}/>
                </div>
            </div>

            <div className={'grocery-item-left noselect'}
                 onClick={onItemClicked}
            >
                <div className={`grocery-item-title noselect`}>
                    <div className={'span-list-title noselect'}
                        name={'span-list-title'}
                          id={`title_${id}`}
                          contentEditable={editMode}
                          onBlur={onNameFocusOut}
                          suppressContentEditableWarning={true}
                    >{name}</div>
                    <div className={'grocery-item-total noselect'}>{totalItems} מוצרים</div>
                </div>
                <div className={'arrow-into-icon'}><ArrowBackIosIcon/></div>
            </div>
        </div>
        {isLastList && !isLiveSharing ? <div className={'info-make-live-sharing'}>
            <b>^</b>
            <br/>
            לחצו כאן כדי לשתף את הרשימה
        </div> : null}
        <Snackbar className={'are-u-sure-container'}
                  anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                  }}
                  open={showAreYouSureModal}
                  onClose={()=>setShowAreYouSureModal(false)}
                  message={`האם אתה בטוח שברצונך למחוק את "${name}" ?`}
                  action={
                      <React.Fragment>
                          <Button
                              onClick={()=>setShowAreYouSureModal(false)}
                              variant="contained"
                              color="primary"
                              startIcon={<CloseIcon/>}
                          >
                              לא
                          </Button>
                          <Button
                              onClick={deleteGroceryList}
                              variant="contained"
                              color="primary"
                              startIcon={<CheckIcon/>}
                          >
                              כן
                          </Button>
                      </React.Fragment>
                  }
        />
    </React.Fragment>
}