import React, {useEffect, useState} from "react";
import TextField from "@material-ui/core/TextField";
import SingletoneStoreClass from "../../store/store";
import Button from "@material-ui/core/Button";
import './editGroceryModal.scss'

const store = SingletoneStoreClass.getInstance();

const AddGroceryModal = (props) => {

    const CODE_TO_NAME = store.getCodeToName();


    const { id, setOpenEditModal, categoryName, setGroceries, groceryCode } = props;

    const [newGroceryName, setNewGroceryName] = useState(CODE_TO_NAME[groceryCode] ? CODE_TO_NAME[groceryCode] : groceryCode);

    const onGroceryNameChanged = (value)=>{
        setNewGroceryName(value.target.value);
    };

    const onSendClick = ()=>{
        store.updateGroceryName({id, category: categoryName, code: groceryCode, value: newGroceryName})
        setOpenEditModal(false);
        setGroceries({...store.getGroceries({id})})
    };

    const isDisabled = ()=>{
        if(CODE_TO_NAME[groceryCode]){
            return newGroceryName === CODE_TO_NAME[groceryCode]
        }
        return newGroceryName === groceryCode;
    }

    return (
        <div className={`edit-grocery-manually-modal`}>
            <h2>עריכת שם פריט</h2>
            <TextField label="שם פריט" value={newGroceryName} onChange={onGroceryNameChanged}/>
            <Button className={'modal-send-button'} variant="contained" color="primary" onClick={onSendClick} disabled={isDisabled()}>
                שמור!
            </Button>
        </div>
    )

};

export default AddGroceryModal;