import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import './index.css';
import App from './App';

if(!window.location.host.includes('localhost')) Sentry.init({
    dsn: "https://a26672e201de4a7a88ecc8b3c57301cd@o976516.ingest.sentry.io/5932812",
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

ReactDOM.render(<App/>,document.getElementById('root'));


if('serviceWorker' in navigator){
    console.log("serviceWorker available for this browser.");
    navigator.serviceWorker.register(`${window.location.origin}/sw.js`, ).then(()=>{
        console.log("Service worker registered!");
    });
}else{
    console.log("serviceWorker does't available for this browser.");
}