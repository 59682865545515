import React, {useEffect, useState} from "react";

import './amount.scss';
import SingletoneStoreClass from "../../../store/store";
const store = SingletoneStoreClass.getInstance();


const Amount = (props)=>{

    const {id, code, categoryName, setGroceries, groceries} = props;


    useEffect(()=>{
        setValue((typeof groceries[categoryName][code] === 'boolean') ? 1 : groceries[categoryName][code]);
    },[groceries]);


    const [value, setValue] = useState((typeof groceries[categoryName][code] === 'boolean') ? 1 : groceries[categoryName][code]);


    return (<div className={`amount-container ${value && value > 1 ? 'enable' : ''}`}>
        <div className={'plus-amount'} onClick={async ()=>{
            const newValue = value + 1;
            setValue(newValue);
            store.updateGroceries({id, category: categoryName, code, value: newValue, updatePastEvents: false});
            setGroceries({...store.getGroceries({id})});
        }}>+</div>
        <div className={'amount-value'}>{value}</div>
        <div className={'minus-amount'} onClick={()=>{
            if(value > 1){
                const newValue = value - 1;
                setValue(newValue);
                store.updateGroceries({id, category: categoryName, code, value: newValue});
                setGroceries({...store.getGroceries({id})});
            }
        }}>-</div>
    </div>)

}

export default Amount;