import LZString from 'lz-string';
import config from "./config/config";

export function downloadJson(text, name, type) {
    const file = new Blob([text], {type: type});
    const isIE = /*@cc_on!@*/false || !!document.documentMode;
    if (isIE) {
        window.navigator.msSaveOrOpenBlob(file, name);
    } else {
        var a = document.createElement('a');
        a.href = URL.createObjectURL(file);
        a.download = name;
        a.click();
    }
}

export function getUrlParams() {
    try {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const compressedCodes = urlParams.get('codes');
        if (!compressedCodes) return null;
        const codesS = LZString.decompressFromEncodedURIComponent(compressedCodes);
        return codesS.split(",");
    } catch (e) {
        console.warn('got error while getUrlParams:', e);
        return null;
    }
}

export function getLiveSharingId() {
    try {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const id = urlParams.get('liveSharing');
        if (!id) return null;
        return id;
    } catch (e) {
        console.warn('got error while getLiveSharingId:', e);
        return null;
    }
}

export function getLiveSharingName() {
    try {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const name = urlParams.get('name');
        if (!name) return null;
        return name;
    } catch (e) {
        console.warn('got error while getLiveSharingName:', e);
        return null;
    }
}

export function sendFeedback() {
    const msg = `*פידבק על האפליקצייה:* : %0A`;
    window.location.href = `whatsapp://send?text=${msg}&phone=${config.feedbackPhone}`;
}

export function shareApp(logicalNAme) {
    if (navigator.share) navigator.share({
        title: 'מנהל הקניות שלי!',
        url: `${window.location.origin}/${logicalNAme}`
    });
}