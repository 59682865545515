import React, {useEffect} from 'react';
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import {ThemeProvider} from "@material-ui/core/styles";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import TextField from "@material-ui/core/TextField/TextField";
import IconButton from "@material-ui/core/IconButton";
import MicIcon from '@material-ui/icons/Mic';
import Icon from "@material-ui/core/Icon";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import SingletoneStoreClass from "../../../store/store";
import './input.scss';
import soundStart from '../../../sounds/beep-start.wav';
import Popper from './popper/popper';
import Toast from '../../toast/toast';

const store = SingletoneStoreClass.getInstance();

const ENTER_KEY_CODE = 13;
const ESC_KEY_CODE = 27;

const BLACK_LIST_WORDS = {
    "וגם": true,
    "או": true,
}

let recognition, speechToText = '';

export default function Input(props) {
    const CODE_TO_NAME = store.getCodeToName();

    const [recordingIsOn, setRecordingIsOn] = React.useState(false);
    const [currentTextToSpeech, setCurrentTextToSpeech] = React.useState('');
    const [openPopper, setOpenPopper] = React.useState(false);
    const [popperText, setPopperText] = React.useState('במכשיר זה המיקרופון לא נתמך. לך להגדרות ואפשר שימוש במיקרופון.');

    const {inputText, setInputText, addManuallyNewGrocery, onChooseButtonClicked, setAddGrocery, addGrocery} = props;
    const addGroceryBySpeech = (text)=>{
        const words = text.split("וגם").join("-").split("גם").join("-").split("-");
        for(let i=0; i< (words.length); i++){
            const word = words[i];
            if(!BLACK_LIST_WORDS[word]){
                addManuallyNewGrocery(word.trim());
            }
        }
    }

    const onInputChange = (event) => {
        const newValue = event.target.value;
        // firebase limitations: Keys must be non-empty strings and can't contain ".", "#", "$", "/", "[", or "]"
        if(newValue.includes('.') || newValue.includes(',') || newValue.includes('#')
            || newValue.includes('$') || newValue.includes('/')
            || newValue.includes('[') || newValue.includes(']')) return;
        setInputText(event.target.value);
    };

    const onInputKeyUpPressed = (event) => {
        if (event.keyCode === ESC_KEY_CODE) setInputText('');
        if (event.keyCode === ENTER_KEY_CODE) onChooseButtonClicked();
    };

    const onAutoCompleteChanged = (event, value, reason) => {
        if (value && reason === "select-option") {
            setInputText(value);
            setAddGrocery(addGrocery + 1);
        }
    };

    function playSound(audio) {
        // audio supported?
        if (typeof window.Audio === 'function') {
            const audioElem = new Audio(audio);
            audioElem.play();
        }
    }

    const onStartRecord = (event)=>{
        if(recordingIsOn) return;
        if (window.hasOwnProperty('webkitSpeechRecognition')) {
            setRecordingIsOn(true);

            if(!recognition) recognition = new window.webkitSpeechRecognition();

            // recognition.continuous = true;
            recognition.interimResults = true;

            recognition.lang = "he";
            try{
                recognition.start();
            }catch (e){
                console.warn('got error while recognition.start:' ,e)
            }

            recognition.onstart = function(e) {
            };

            recognition.onresult = function(e) {
                let totalText = '';
                for(let result of e.results){
                    totalText += `${result[0].transcript}`;
                }
                speechToText = totalText;
                setCurrentTextToSpeech(totalText)
            };

            recognition.onerror = function(e) {
                console.log("onerror: --- e=", e);
                setPopperText('לא ניתן להשתמש במיקרופון, אנא אשר את השימוש במיקרופון ונסה שוב.');
                setOpenPopper(true);
                setTimeout(()=>setOpenPopper(false), 1000 * 5);
                onEndRecord();
            };

            recognition.onend = function(e) {
                console.log("onend: --- e=", e)
                onEndRecord();
            }

        }
        playSound(soundStart);
    }

    const onEndRecord = (event)=>{
        setRecordingIsOn(false);
        recognition.stop();
        // if(!recordingIsOn) playSound(soundEnd)
        addGroceryBySpeech(speechToText);
        speechToText = '';
        setCurrentTextToSpeech('');
    }

    const onRecordButtonClicked = ()=>{
        if(!recordingIsOn) onStartRecord();
        else onEndRecord();
    }

    return (
        <div className={"input-field"}>
            {recordingIsOn ?
                <Toast addGrocery={recordingIsOn} text={"השתמש במילה ׳וגם׳ בין מוצר למוצר"} severity={"error"} disableAutoHideDuration={true} anchorOrigin={{vertical: 'top', horizontal: 'center'}}/>
                : null}
            <div className={'input-container'}>
                <Popper text={popperText} open={openPopper} />
                <button className={`record-container ${recordingIsOn ? 'recording' : ''}`} onClick={onRecordButtonClicked}>
                    <MicIcon/>
                </button>
                {recordingIsOn ?
                    <TextField
                        className={'recording-text-field-container'}
                        id="outlined-multiline-flexible"
                        label="הקלטה בזמן אמת"
                        multiline
                        maxRows={100}
                        value={currentTextToSpeech}
                        variant="outlined"
                    /> :
                    <Autocomplete
                        className={'input-auto-complete'}
                        id="free-solo-demo"
                        freeSolo
                        options={Object.values(inputText ? Object.values(CODE_TO_NAME) : [])}
                        renderInput={(params) => (
                            <ThemeProvider theme={createMuiTheme({direction: 'rtl'})}>
                                <div className={'text-field-container'} dir={'rtl'}>
                                    <TextField
                                        className={'text-field-add-grocery'}
                                        onKeyUp={onInputKeyUpPressed}
                                        onClick={(e) => {
                                            if (e && e.target && ['svg', 'path'].includes(e.target.tagName)) setInputText('');
                                        }}
                                        onChange={onInputChange} {...params}
                                        label="הוספת פריט"
                                        margin="normal"
                                        variant="outlined"
                                    />
                                    <IconButton disabled={inputText === ''} onClick={onChooseButtonClicked} className={`add-button-plus ${inputText === '' ? '' : 'selected-plus'}`}
                                                color="primary" component="span">
                                        <AddCircleIcon/>
                                    </IconButton>
                                </div>
                            </ThemeProvider>
                        )}
                        clearOnEscape
                        onChange={onAutoCompleteChanged}
                        autoComplete
                        autoHighlight
                        inputValue={inputText}
                        handleHomeEndKeys
                    />
                }

            </div>
        </div>
    );
}