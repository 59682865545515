import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { useSpring, animated } from 'react-spring';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import SingletoneStoreClass from "../../../store/store";
import Button from '@material-ui/core/Button';
import { ReactComponent as CopyIcon } from '../../../icons/copy-clipboard.svg';
import { ReactComponent as QrCodeIcon } from '../../../icons/qr-code.svg';
import {SvgIcon} from "@material-ui/core";
import './liveSharingModal.scss'
import Toast from "../../toast/toast";
import QRCode from "react-qr-code";


const store = SingletoneStoreClass.getInstance();

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: '#49A97D',
        borderRadius: '20px',
        height: '206px',
        textAlign: 'center'
    },
}));

const Fade = React.forwardRef(function Fade(props, ref) {
    const { in: open, children, onEnter, onExited, ...other } = props;
    const style = useSpring({
        from: { opacity: 0 },
        to: { opacity: open ? 1 : 0 },
        onStart: () => {
            if (open && onEnter) {
                onEnter();
            }
        },
        onRest: () => {
            if (!open && onExited) {
                onExited();
            }
        },
    });

    return (
        <animated.div ref={ref} style={style} {...other}>
            {children}
        </animated.div>
    );
});

Fade.propTypes = {
    children: PropTypes.element,
    in: PropTypes.bool.isRequired,
    onEnter: PropTypes.func,
    onExited: PropTypes.func,
};

const VIEWS = {
    write: 'WRITE',
    read: 'READ'
}

export default function LiveSharingModal(props) {
    const classes = useStyles();
    const {setLiveSharingModalOpen, id, name, makeListToBeLiveSharing, isLiveSharing} = props;
    const link = `${window.location.origin}/${store.getLogicalName()}?liveSharing=${id}&name=${name.replace(/ /g, '%20')}`;
    const qrLink = `${window.location.origin}/${store.getLogicalName()}?liveSharing=${id}`;

    const [open, setOpen] = React.useState(props.open);
    const [view, setView] = React.useState(VIEWS.write);
    const [makeToast, setMakeToast] = React.useState(0);
    const [showQr, setShowQr] = React.useState(false);

    useEffect(() => {
        setOpen(props.open)
    }, [props.open]);

    const handleClose = () => {
        setOpen(false);
        setLiveSharingModalOpen(false);
        setShowQr(false);
    };

    const renderLiveSharing = ()=>{
        return <React.Fragment>
            <div className={'modal-text-container'}>
                {makeToast ? <Toast addGrocery={makeToast} text={'רשימה הועתקה'}/> : null}
                <div className={'share-modal-title'}>עריכת רשימה משותפת</div>
                <div className={'share-modal-name-title'}>שם רשימה: <b>{name}</b></div>
                <div className={'share-modal-sub-title'}>שתפו קישור זה ותוכלו לצפות ולערוך רשימה זו יחד</div>
                {isLiveSharing ? <div className={'share-modal-sub-title'}>ID: <b>{id.split('id_')[1]}</b>
                    <SvgIcon
                        className={'share-modal-sub-title-copy'}
                        component={CopyIcon}
                        viewBox="0 0 600 476.6"
                        onClick={async ()=>{
                            await makeListToBeLiveSharing();
                            navigator.clipboard.writeText(id.split('id_')[1]).then(function() {
                                console.log('Async: Copying to clipboard was successful!');
                                setMakeToast(makeToast + 1);
                                setTimeout(()=>{setMakeToast(0)},1000)
                            }, function(err) {
                                console.error('Async: Could not copy text: ', err);
                            });
                        }}
                    />
                </div> : null}
            </div>

            <div className={`buttons-actions-container`}>
                <Button
                    className={'share-live-list-button-modal'}
                    variant="contained"
                    color="primary"
                    size="medium"
                    startIcon={<SvgIcon
                        component={CopyIcon}
                        viewBox="0 0 600 476.6"
                    />}
                    style={{direction: 'ltr'}}
                    onClick={async ()=>{
                        await makeListToBeLiveSharing();
                        navigator.clipboard.writeText(link).then(function() {
                            console.log('Async: Copying to clipboard was successful!');
                        }, function(err) {
                            console.error('Async: Could not copy text: ', err);
                        });
                        handleClose();
                    }}
                >
                    העתקה
                </Button>
                <Button
                    className={'copy-live-list-button-modal'}
                    variant="contained"
                    color="primary"
                    size="medium"
                    startIcon={<WhatsAppIcon />}
                    style={{direction: 'ltr'}}
                    onClick={async ()=>{
                        await makeListToBeLiveSharing();
                        let text =`*רשימה משותפת (בזמן אמת) ${name}* : %0A`;
                        text += encodeURIComponent(link);
                        console.info(`the whats up text is: ${text}`);
                        window.location.href = `whatsapp://send?text=${text}`;
                        handleClose();
                    }}
                >
                    שיתוף ב-
                </Button>
                <Button
                    className={'share-live-list-button-modal'}
                    variant="contained"
                    color="primary"
                    size="medium"
                    startIcon={<SvgIcon
                        component={QrCodeIcon}
                        viewBox="0 0 600 476.6"
                    />}
                    style={{direction: 'ltr'}}
                    onClick={async ()=>{
                        await makeListToBeLiveSharing();
                        setShowQr(true);
                    }}
                >
                    ברקוד
                </Button>
            </div>
        </React.Fragment>
    }

    const renderReadonly = ()=>{
        const url = `${window.location.origin}/${store.getLogicalName()}?codes=${store.groceriesToEncodeURIComponent({id})}&name=${name.replace(/ /g, '%20')}`;

        return <React.Fragment>
            <div className={'modal-text-container'}>
                <div className={'share-modal-title'}>שיתוף רשימה לקריאה בלבד</div>
                <div className={'share-modal-name-title'}>שם רשימה: {name}</div>
                <div className={'share-modal-sub-title'}>שתף קישור זה עם חברים שאתה לא רוצה שיוכלו לערוך את הרשימה</div>
            </div>

            <div className={`buttons-actions-container`}>
                <Button
                    className={'share-live-list-button-modal'}
                    variant="contained"
                    color="primary"
                    size="medium"
                    startIcon={<SvgIcon
                        component={CopyIcon}
                        viewBox="0 0 600 476.6"
                    />}
                    style={{direction: 'ltr'}}
                    onClick={()=>{
                        navigator.clipboard.writeText(url).then(function() {
                            console.log('Async: Copying to clipboard was successful!');
                        }, function(err) {
                            console.error('Async: Could not copy text: ', err);
                        });
                        handleClose();
                    }}
                >
                    העתקה
                </Button>
                <Button
                    className={'copy-live-list-button-modal'}
                    variant="contained"
                    color="primary"
                    size="medium"
                    startIcon={<WhatsAppIcon />}
                    style={{direction: 'ltr'}}
                    onClick={()=>{
                        if(navigator.share) navigator.share({
                            title: name,
                            url
                        });
                        handleClose();
                    }}
                >
                    שיתוף ב-
                </Button>
            </div>

        </React.Fragment>

    }



    return (
        <div>
            <Modal
                aria-labelledby="spring-modal-title"
                aria-describedby="spring-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <div className={`modal-sharing-container ${showQr ? 'show-qr-on' : ''}`}>
                            {showQr ? <QRCode
                                    className={"qr-code-container"}
                                    value={qrLink} size={200}
                                    bgColor={'#F5F2F2'}
                                /> :
                            <React.Fragment>
                                <div className={'modal-top-buttons'}>
                                    <Button
                                        className={view === VIEWS.read ? 'modal-share-button-clicked' : 'black-text'}
                                        variant="contained"
                                        color="primary"
                                        size="medium"
                                        onClick={()=>{
                                            setView(VIEWS.read)
                                        }}
                                    >שיתוף לצפיה בלבד</Button>
                                    <Button
                                        className={view === VIEWS.write ? 'modal-share-button-clicked' : 'black-text'}
                                        variant="contained"
                                        color="primary"
                                        size="medium"
                                        onClick={()=>{
                                            setView(VIEWS.write)
                                        }}
                                    >שיתוף עריכה</Button>
                                </div>
                                <div className={'modal-body-container'}>
                                    {view === VIEWS.write ? renderLiveSharing() : renderReadonly()}
                                </div>
                            </React.Fragment>}

                        </div>

                    </div>
                </Fade>
            </Modal>
        </div>
    );
}