import config from '../config/config';
import {getUrlParams, getLiveSharingId, getLiveSharingName} from '../utils';
import DbManager from './dbManager';
import LZString from 'lz-string';

const localStorage = window.localStorage;

class SingletoneStoreClass {
    constructor() {
        this.logicalName = null;
        this.groceryListItems = SingletoneStoreClass.getFromLocalStorage('groceryListItems') || [];
        this.shareCodes = getUrlParams();
        this.liveSharingId = getLiveSharingId();
        this.pastEvents = [];
        this.manualGroceries = SingletoneStoreClass.getFromLocalStorage('manualGroceries') || {};
        this.CATEGORIES = null;
        this.codeToName = null;
        this.groceries = {};
        this.liveSharing = SingletoneStoreClass.getFromLocalStorage('liveSharing') || {};
        this.selected = {};
        this._init();
    }

    _init() {
        this.pastEvents = [];
        this.CATEGORIES = this._calcCATEGORIES();
        this.codeToName = this._calcCodeToName();
        // this.groceries = SingletoneStoreClass.getFromLocalStorage('groceries') || this._getDefault();
        this.groceries = SingletoneStoreClass.getFromLocalStorage('groceries') || {};
        // this.selected = SingletoneStoreClass.getFromLocalStorage('selected') || this._getDefault();
        this.selected = SingletoneStoreClass.getFromLocalStorage('selected') || {};

        //handle case i added new category...
        Object.keys(this.CATEGORIES).forEach((category => {
            const hebrewCategoryName = this.CATEGORIES[category].hebrew;
            //todo: change here
            Object.keys(this.groceries).forEach(id => {
                if (!this.groceries[id][hebrewCategoryName]) this.groceries[id][hebrewCategoryName] = {};
                if (!this.selected[id][hebrewCategoryName]) this.selected[id][hebrewCategoryName] = {};
            })
        }))

        Object.keys(this.liveSharing).forEach(id => {
            DbManager.subscribe({id});
        });
    }

    _calcCATEGORIES() {
        const {CATEGORIES} = config;
        Object.keys(CATEGORIES).forEach(category => {
            if (this.manualGroceries[category]) {
                CATEGORIES[category].foods = CATEGORIES[category].foods.concat(this.manualGroceries[category]);
            }
        });
        return CATEGORIES;
    }

    _getDefault() {
        return Object.keys(this.CATEGORIES).reduce((groceries, categoryKey) => {
            groceries[this.CATEGORIES[categoryKey].hebrew] = {};
            return groceries;
        }, {});
    }

    exit(){
        this.logicalName = null;
        localStorage.removeItem('logicalName');
    }

    setLogicalName(logicalName){
        this.logicalName = logicalName;
        localStorage.setItem('logicalName', logicalName);
    }

    getLogicalName(){
        return this.logicalName || localStorage.getItem('logicalName');
    }

    getGroceryListItems(){
        return this.groceryListItems;
    }

    getGroceryListName(id){
        if(!id) return null;
        return this.groceryListItems.find(gl=>gl.id === id).name;
    }

    saveGroceryListItems(groceryListItems, id, groceries){
        this.groceryListItems = groceryListItems;
        this.groceryListItems.forEach(listItem=>{
            if(!this.groceries[listItem.id]){
                this.groceries[listItem.id] = this._getDefault();
            }
            if(id && groceries && listItem.id === id){
                //update live sharing from the cloud
                Object.keys(groceries).forEach(category=>{
                    this.groceries[listItem.id][category] = groceries[category];
                })
            }
            if(!this.selected[listItem.id]) this.selected[listItem.id] = this._getDefault();
        })
        Object.keys(this.selected).forEach(id=>{
            if(!this.groceryListItems.find(li=>li.id === id)){
                delete this.groceries[id];
                delete this.selected[id];
            }
        })
        localStorage.setItem('groceryListItems', JSON.stringify(groceryListItems));
        localStorage.setItem('groceries', JSON.stringify(this.groceries));
        localStorage.setItem('selected', JSON.stringify(this.selected));
    }

    getLiveSharing(){
        return this.liveSharing;
    }

    async createLiveSharing(id){
        try{
            await DbManager.save({id, groceries: this.groceries[id]});
            return true
        }catch (e){
            console.error('got error while createLiveSharing: ',e)
            return false;
        }
    }

    addLiveSharing(id){
        this.liveSharing[id] = true;
        localStorage.setItem('liveSharing', JSON.stringify(this.liveSharing));
        DbManager.subscribe({id});
        const name = getLiveSharingName();
        if(name){
            this.groceryListItems.push({id, name});
        }
    }

    removeLiveSharing(id){
        delete this.liveSharing[id];
        localStorage.setItem('liveSharing', JSON.stringify(this.liveSharing));
        DbManager.unsubscribe({id})
    }

    async updateLiveShareList({id}){
        try {
            await DbManager.save({id, groceries: this.groceries[id]});
            return true;
        }catch (e){
            console.error(`got exception while updating live sharing list: ${e}`);
            return false;
        }
    }

    getGroceries({id}) {
        return this.groceries[id];
    }

    isShareMode() {
        return !!this.shareCodes || !!this.liveSharingId;
    }

    getShareCodes() {
        return this.shareCodes;
    }

    disableShareMode() {
        this.shareCodes = null;
        this.liveSharingId = null;
    }

    getLiveSharingId() {
        return this.liveSharingId;
    }

    getPastEvents(){
        return this.pastEvents;
    }

    getLastPastEvents(){
        return this.pastEvents.pop();
    }

    removePastEvents(){
        this.pastEvents = [];
    }

    getSelectedGroceries({id}) {
        return this.selected[id];
    }

    updateBulkOfGroceries({id, groceries}){
        this.groceries[id] = groceries;
        Object.keys(this.selected[id]).forEach(category=>{
            Object.keys(this.selected[id][category]).forEach(code=>{
                if(!this.groceries[id][category][code]){
                    //deselect items that have removed from cloud
                    this.updateGrocerySelected({id, category, code, selected: false});
                }
            });
        });

    }

    async updateGroceries({id, category, code, value, updatePastEvents=true}) {
        if(updatePastEvents) this.pastEvents.push({category, code, value}); //todo: change the pastEvents
        if (value){
            if(!this.groceries[id]){
                this.groceries[id] = {};
                this.groceries[id][category] = {};
            }
            else if(!this.groceries[id][category]){
                this.groceries[id][category] = {};
            }
            this.groceries[id][category][code] = value;
        }
        else {
            delete this.groceries[id][category][code];
            this.updateGrocerySelected({id, category, code, selected: false});
        }
        localStorage.setItem('groceries', JSON.stringify(this.groceries));
        if(this.liveSharing[id]) return await this.updateLiveShareList({id});
        return true;
    }

    async updateGroceryName({id, category, code, value}) {
        if(!value) return;
        delete this.groceries[id][category][code];
        this.groceries[id][category][value] = true;
        this.updateGrocerySelected({id, category, code, selected: false});
        localStorage.setItem('groceries', JSON.stringify(this.groceries));
        if(this.liveSharing[id]) return await this.updateLiveShareList({id});
        return true;
    }

    updateGrocerySelected({id, category, code, selected}) {
        if (selected) this.selected[id][category][code] = true;
        else delete this.selected[id][category][code];
        localStorage.setItem('selected', JSON.stringify(this.selected));
    }

    selectAll({id, status}){
        if(!this.groceries[id]) return;
        Object.keys(this.groceries[id]).forEach(category=>{
            Object.keys(this.groceries[id][category]).forEach(code=>{
                this.updateGrocerySelected({id, category, code, selected: !!status});
            })
        })
    }

    deleteAll({id}) {
        Object.keys(this.selected[id]).forEach(category=>{
            Object.keys(this.selected[id][category]).forEach(code=>{
                this.updateGroceries({id, category, code, value: false});
            });
        });
    }

    getAmountOfItems({id}) {
        if(!this.groceries[id]) return 0;
        const amount = Object.values(this.groceries[id]).reduce((acc, value)=> acc + Object.keys(value).length, 0);
        return amount;
    }

    getCodeToName() {
        return this.codeToName;
    }

    _calcCodeToName() {
        console.log("[store] calculating code to name...");
        const map = {};
        Object.keys(this.CATEGORIES).forEach(category => {
            const {foods} = this.CATEGORIES[category];
            foods.forEach(food => {
                map[food.code] = food.name;
            })
        });
        return map;
    };

    getDictionary() {
        const makeUnique = (foods)=>{
            const map = {};
            foods.forEach(food=>{
                if(!map[food.code]) map[food.code] = food;
            });
            return Object.values(map);
        };

        const dictionary = {};
        Object.keys(this.CATEGORIES).forEach(category=>{
            dictionary[category] = {};
            dictionary[category].hebrew = this.CATEGORIES[category].hebrew;
            dictionary[category].foods = makeUnique(this.CATEGORIES[category].foods);
        });

        return dictionary;
    }

    getCategories() {
        const map = {};
        Object.keys(this.CATEGORIES).forEach(c => map[c] = this.CATEGORIES[c].hebrew);
        return map;
    }

    addGroceryManually({id, groceryName, categoryName, aliases}) {
        const category = this.CATEGORIES[categoryName];
        const maxCode = category.foods.reduce((acc, food) => {
            if (food.code > acc) acc = food.code;
            return acc;
        }, 0);
        const newFood = {
            code: maxCode + 1,
            name: groceryName,
            aliases: aliases.filter(a => a)
        };
        if (!this.manualGroceries[categoryName]) {
            this.manualGroceries[categoryName] = [newFood];
        } else {
            const exist = this.manualGroceries[categoryName].find(f => f.name === groceryName);
            if (!exist) this.manualGroceries[categoryName].push(newFood);
        }
        localStorage.setItem('manualGroceries', JSON.stringify(this.manualGroceries));

        delete this.groceries[id]['כללי'][groceryName];
        delete this.selected[id]['כללי'][groceryName];
        localStorage.setItem('groceries', JSON.stringify(this.groceries));
        localStorage.setItem('selected', JSON.stringify(this.selected));

        this.updateGroceries({id, category:category.hebrew, code: newFood.code, value: true});

        this._init();
    }

    deleteAllManualGroceries(){
        this.manualGroceries = {};
        localStorage.removeItem('manualGroceries');
        this._init();
    }

    groceriesToString({id}){
        let s = '';
        Object.keys(this.groceries[id]).forEach(grocery=>{
            if(!this.groceries[id][grocery]) return;
            const codes = Object.keys(this.groceries[id][grocery]);
            if(codes.length === 0) return;
            const title = grocery;
            s+=`*${title}* : %0A`;
            codes.forEach(code=>{
               s+=`${(this.selected[id] && this.selected[id][grocery] && this.selected[id][grocery][code]) ? '✓' : '-'} ${this.codeToName[code] ? this.codeToName[code] : code} ${(typeof this.groceries[id][grocery][code] === 'number' && this.groceries[id][grocery][code] > 1) ? `${this.groceries[id][grocery][code]}`+`x` : ''} %0A`;
            });
        });
        return s;
    }

    groceriesToEncodeURIComponent({id}){
        const groceries = [];
        Object.keys(this.groceries[id]).forEach(grocery=>{
            if(!this.groceries[id][grocery]) return;
            const codes = Object.keys(this.groceries[id][grocery]);
            if(codes.length === 0) return;
            codes.forEach(code=>{
                groceries.push(`${this.codeToName[code] ? this.codeToName[code] : code}`);
            });
        });
        return LZString.compressToEncodedURIComponent(groceries.toString());
    }

    classify(word){
        let category, max = 0;
        for (const categoryKey of Object.keys(this.CATEGORIES)) {
            const {foods} = this.CATEGORIES[categoryKey];
            const hebrewCategory = this.CATEGORIES[categoryKey].hebrew;
            if (foods.length === 0) continue;
            for (const food of foods) {
                let words = [food.name, ...food.aliases];
                if (words.includes(word)) return [hebrewCategory, food.code]; //perfect match
                const strRegExPattern = `${words.join('|')}`;
                if (word.match(new RegExp(strRegExPattern))) {
                    if (word.length > max) {
                        category = hebrewCategory;
                        max = word.length;
                    }
                    if(food.size){
                        category = hebrewCategory;
                        max = food.size;
                    }
                }
            }
        }
        if (category) return [category, word];
        return [this.CATEGORIES.GENERAL.hebrew, word];
    };

    static getInstance() {
        if (!SingletoneStoreClass.instance) {
            SingletoneStoreClass.instance = SingletoneStoreClass.createInstance();
        }
        return SingletoneStoreClass.instance;
    }

    static getFromLocalStorage(key) {
        try {
            const value = localStorage.getItem(key);
            return JSON.parse(value);
        } catch (e) {
            console.error('got error on getFromLocalStorage: ', e);
            return null;
        }
    }

    static instance = null;

    static createInstance() {
        let object = new SingletoneStoreClass();
        return object;
    }

}

export default SingletoneStoreClass;