import React, {useState} from "react";
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import Collapse from '@material-ui/core/Collapse';
import Fade from '@material-ui/core/Fade';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import './categoryContainer.scss';

import Item from '../item/item';

const CategoryContainer = (props) => {
    const [open, setOpen] = useState(true);

    const {id, categoryName, groceriesOfCategory, onDeleteGrocery, selectedGroceries, setSelectedGroceries, setGroceries, groceries, todoMode, firstRender} = props;
    const groceryArr = Object.keys(groceriesOfCategory);

    if (groceryArr.length === 0) return null;

    const onListClick = () => {
        setOpen(!open);
    };

    return (
        <div className={'category-container-list'} key={`category-container-list-${categoryName}`}>
            {todoMode ? null : <React.Fragment>
                <Divider/>
                <div className={'category-header-container'} onClick={onListClick}>
                <div className={'category-header-right'}>
                <List component="nav" aria-label="nested-list-subheader" className={'list-expand-on-off'}
                    subheader={
                    <ListSubheader component="div" id="nested-list-subheader">{categoryName}</ListSubheader>}>
                </List>
            {open ? <ExpandLess/> : <ExpandMore/>}
                </div>
                </div>
            </React.Fragment>}

            {groceryArr.map(groceryCode => {
                    return <Fade in={open} timeout={500} unmountOnExit
                                 key={`category-container-fade-${categoryName}-${groceryCode}`}>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Item id={id} groceryCode={groceryCode} onDeleteGrocery={onDeleteGrocery}
                                  categoryName={categoryName}
                                  selectedGroceries={selectedGroceries} setSelectedGroceries={setSelectedGroceries}
                                  setGroceries={setGroceries}
                                  isSelected={!!selectedGroceries[categoryName][groceryCode]}
                                  groceries={groceries}
                                  todoMode={todoMode}
                                  firstRender={firstRender}
                            />
                        </Collapse>
                    </Fade>
                }
            )}
        </div>
    )
};

export default CategoryContainer;


