import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';

const useStyles = makeStyles((theme) => ({
    paper: {
        border: '1px solid',
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function SimplePopper({text, open}) {
    const classes = useStyles();

    const id = open ? 'simple-popper' : undefined;

    return (
        <div>
            <Popper id={id} open={open} className={'popper-mic-permissions'}>
                <div className={classes.paper}>{text}</div>
            </Popper>
        </div>
    );
}