import React, {useEffect, useState} from 'react';

import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import ShareIcon from '@material-ui/icons/Share';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import Toolbar from "@material-ui/core/Toolbar";
import './navigator.scss';
import Menu from './menu/menu'
import SingletoneStoreClass from "../../store/store";
import firebase from '../../firebase/firebase';
import LiveSharingModal from "../myGrocery/liveSharingModal/liveSharingModal";


const {analytics} = firebase;

const store = SingletoneStoreClass.getInstance();

const Navigator = (props) =>{

    const [liveSharingModalOpen, setLiveSharingModalOpen] = React.useState(false);

    const {id, disableKeys={}, name, forceUpdate, todoMode, setTodoMode} = props;
    const isLiveSharing = !!store.getLiveSharing()[id];

    const listName = store.getGroceryListName(id);

    const onWhatsUpClicked = () =>{
        const text = store.groceriesToString({id});
        window.location.href = `whatsapp://send?text=${text}`;
        analytics.logEvent('onWhatsUpClicked', {text} );
    };

    const makeListToBeLiveSharing = async ()=>{
        if(!isLiveSharing){
            await store.createLiveSharing(id);
            store.addLiveSharing(id);
        }

        if(forceUpdate) forceUpdate(true);
    }

    return (
        <AppBar position="sticky" className={'app-navigator'}>
            {listName ? <LiveSharingModal open={liveSharingModalOpen} setLiveSharingModalOpen={setLiveSharingModalOpen} id={id} name={listName} makeListToBeLiveSharing={makeListToBeLiveSharing} isLiveSharing={isLiveSharing}/> : null}

            <Toolbar disableGutters className={'app-toolbar'}>
                <div className={'app-toolbar-right'}>
                    <IconButton className={'open-menu-button'} edge="start" color="inherit" aria-label="menu">
                        <Menu fontSize="large"/>
                    </IconButton>
                </div>
                <div className={'app-toolbar-left'}>

                    {disableKeys.todo ? null : <button className={'set-todo-button'} onClick={()=>setTodoMode(!todoMode)}>{todoMode ? 'תצוגת מוצרי מזון' : 'תצוגת רשימת משימות'}</button>}

                    {disableKeys.lista ? null :
                        <ShareIcon
                            onClick={async ()=>{
                                setLiveSharingModalOpen(true);
                            }}
                            className={`live-sharing-${isLiveSharing ? 'on' : 'off'}`}
                        />
                    }

                    {disableKeys.whatsup ? null : <IconButton className={'whats-up-share-button'} edge="start" color="inherit" aria-label="menu" disabled={!navigator.share || !id} onClick={onWhatsUpClicked}>
                        <WhatsAppIcon fontSize="large"/>
                    </IconButton>}
                </div>
            </Toolbar>
        </AppBar>
    )
};

export default Navigator;