import React, {useEffect, useState} from 'react';

import './myGrocery.scss'
import SingletoneStoreClass from "../../store/store";
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import GroceryItemList from './groceryItemList/groceryItemList'
import Navigator from "../navigator/navigator";
import ImportList from "./importList/importList";
import db from '../../store/dbManager';
import arrowAnimation from './orange-arrow-animation.gif';
const store = SingletoneStoreClass.getInstance();

export default function MyGrocery(){

    const [groceryList, setGroceryList] = React.useState(store.getGroceryListItems());
    const [liveSharing, setLiveSharing] = React.useState(store.getLiveSharing());
    const [newListId, setNewListId] = React.useState(null);

    store.removePastEvents();

    useEffect(() => {
        const refs = Object.keys(liveSharing).map(liveSharingId => db.getRef({id: liveSharingId}));

        refs.forEach(ref=>{
            ref.on('value', (snapshot) => {
                console.log('---[MyGrocery]snapshot.key=',snapshot.key);
                console.log('---[MyGrocery]snapshot.val()=',snapshot.val());
                const LiveSharingId = snapshot.key;
                const val = snapshot.val();
                if(val){
                    if(!groceryList.find(gl => gl.id === LiveSharingId)){
                        console.info(`got live sharing from DB that not exist on the store. id= ${LiveSharingId}`);
                        const lastGroceryList = groceryList;
                        lastGroceryList.push({id: LiveSharingId, name: 'רשימה משותפת בזמן אמת' });
                        store.saveGroceryListItems(lastGroceryList, LiveSharingId, val.groceries);
                        updateGroceryListFromStore();
                    }else{
                        //updating existing live sharing lists:
                        store.saveGroceryListItems(groceryList, LiveSharingId, val.groceries);
                        updateGroceryListFromStore();
                    }
                }
            });
        });

        return function cleanup() {
            const refs = Object.keys(liveSharing).map(liveSharingId => db.getRef({id: liveSharingId}));
            refs.forEach(ref=>{
                console.log(`[MyGrocery] unsubscribe ref: ${ref}`);
                ref.off();
            })

        };
    }, []);

    const updateGroceryListFromStore = ()=>{
        setGroceryList(JSON.parse(JSON.stringify(store.getGroceryListItems())));
    }

    const addLiveSharingFromStore = ()=>{
        setLiveSharing(JSON.parse(JSON.stringify(store.getLiveSharing())));
    }

    const addNewGroceryList = (name = 'הרשימה שלי') =>{
        function makeid(length) {
            var result           = '';
            var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < length; i++ ) {
                result += characters.charAt(Math.floor(Math.random() *
                    charactersLength));
            }
            return result;
        }

        const lastGroceryList = groceryList;
        const groceryId = `id_${makeid(7)}`;
        lastGroceryList.push({id: groceryId, name });
        store.saveGroceryListItems(lastGroceryList);
        updateGroceryListFromStore();
        setNewListId(groceryId)
        return groceryId;
    }

    const deleteGroceryListItem = (id)=>{
        const lastGroceryList = groceryList;
        store.saveGroceryListItems(JSON.parse(JSON.stringify(lastGroceryList.filter(gl=>gl.id !== id))))
        updateGroceryListFromStore();
    }

    const editGroceryListItemName = ({id, newName})=>{
        const lastGroceryList = groceryList;
        const item = groceryList.find(gl=>gl.id === id);
        item.name = newName
        store.saveGroceryListItems(lastGroceryList);
        updateGroceryListFromStore();
    }

    // for debugging only!!!
    // useEffect(() => {
    //     console.log("----MyGrocery state: ", {groceryList})
    // }, [groceryList]);

    return <div className={'my-grocery-container'}>
        <Navigator onBacButtonClicked={null} id={null} disableKeys={{share: true, whatsup: true, lista: true, todo: true}}/>

        {groceryList.length === 0 ?
            <img className={'orange-arrow-animation'} src={arrowAnimation} alt="arrow" />
            : null}
        <div className={'list-of-groceries'}>
            {groceryList.map((gl, index)=><GroceryItemList name={gl.name} id={gl.id} key={gl.id}
                                                  totalItems={store.getAmountOfItems({id: gl.id})}
                                                  deleteGroceryListItem={deleteGroceryListItem}
                                                  editGroceryListItemName={editGroceryListItemName}
                                                  updateLiveSharingFromStore={addLiveSharingFromStore}
                                                  editMode={gl.id === newListId}
                                                  isLastList={index === groceryList.length -1}
                                                  isLiveSharing={!!liveSharing[gl.id]}/>)}
        </div>
        <div className={'add-new-grocery-list-button-container'}>
            <ImportList updateGroceryListFromStore={updateGroceryListFromStore} addLiveSharingFromStore={addLiveSharingFromStore} groceryList={groceryList}/>
            <Fab className={'add-new-grocery-list-button'} aria-label="add">
                <AddIcon onClick={()=>addNewGroceryList()}/>
            </Fab>
        </div>
        <div className={'style-background-button'}></div>
    </div>

}