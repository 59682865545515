import React from 'react';
import './startPage.scss';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";

import config from '../../config/config'
import SingletoneStoreClass from "../../store/store";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

const store = SingletoneStoreClass.getInstance();

export default function StartPage(){
    const classes = useStyles();
    const [logicalName, setLogicalName] = React.useState('');

    const handleChange = (event) => {
        const {value} = event.target;
        setLogicalName(value);
        store.setLogicalName(value);
    };

    const renderEnterButton = ()=> <Button className={'start-page-enter-button'} variant="contained" color="primary" disabled={!logicalName}>כנס!</Button>

    return <div className={'start-page-container'}>
        <div className={'start-page-title'}>
            ברוכים הבאים למנהל הקניות שלך!
        </div>
        <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">בחר את המכולת שלך</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={logicalName}
                onChange={handleChange}
            >
                {config.STORES.map(s=><MenuItem className={'start-page-menu-item'} value={s.logicalName}>{s.name} <img className={'select-icon'} src={s.logo}/></MenuItem>)}
            </Select>
        </FormControl>


        {logicalName ? <Link to={`/${logicalName}`}>{renderEnterButton()}</Link> : renderEnterButton()}


    </div>


}