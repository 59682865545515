import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Button from "@material-ui/core/Button";
import './shareModal.scss';
import { useHistory } from 'react-router-dom';
import SingletoneStoreClass from "../../store/store";
import {getLiveSharingName} from '../../utils';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

const store = SingletoneStoreClass.getInstance();

export default function ShareModal(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const LiveSharingId = store.getLiveSharingId();

    const name = getLiveSharingName();

    const history = useHistory();

    const { addNewGroceryList } = props;

    const handleClose = () => {
        setOpen(false);
        history.push(`/${store.getLogicalName()}`);
        store.disableShareMode();
    };

    const _addBulkOfSharedGroceries = () =>{
        const codes = store.getShareCodes();
        console.log("-----_addBulkOfSharedGroceries, code:",codes);
        const newListId = addNewGroceryList(name || ` רשימה משותפת לקריאה (${new Date().toLocaleDateString()})`);
        codes.forEach(c=>{
            const [category, code] = store.classify(c);
            store.updateGroceries({id: newListId, category, code, value: true});
        });
    };

    const mergeGroceries = () => {
        _addBulkOfSharedGroceries();
        handleClose();
        history.push(`/${store.getLogicalName()}/list`);
    };

    const addLiveSharingList = ()=>{
        if(store.getLiveSharing()[LiveSharingId]){
            console.info(`live sharing (${LiveSharingId}) already exist!`);
            history.push(`/${store.getLogicalName()}/list`);
            history.push(`/${store.getLogicalName()}/list/${LiveSharingId}`);
            return;
        }

        store.addLiveSharing(LiveSharingId);
        handleClose();
        history.push(`/${store.getLogicalName()}/list`);
    }

    if(LiveSharingId && store.getLiveSharing()[LiveSharingId]){
        history.push(`/${store.getLogicalName()}/list`);
        history.push(`/${store.getLogicalName()}/list/${LiveSharingId}`);
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 1500,
            }}
        >
            <Fade in={open}>
                <div className={classes.paper}>
                    {LiveSharingId ? <h2>{`האם ברצונך לקבל רשימת שיתוף${name ? ` (${name})`: ''} בזמן אמת?`}</h2> : <h2>{`האם ברצונך לקבל רשימה${name ? ` (${name})` : ''} ממקור חיצוני אחר?`}</h2>}
                    <ButtonGroup className={'share-modal-buttons-container'} size="large" color="primary"
                                 aria-label="large outlined primary button group">
                        <Button onClick={()=>LiveSharingId ? addLiveSharingList() : mergeGroceries()}>כן</Button>
                        <Button onClick={handleClose}>לא</Button>
                    </ButtonGroup>
                </div>
            </Fade>
        </Modal>
    );
}