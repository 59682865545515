import React, {useEffect, useState} from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from "@material-ui/lab/Alert";

const Toast = (props) =>{

    const {addGrocery, text, severity = "success", disableAutoHideDuration=false, anchorOrigin={vertical: 'bottom', horizontal: 'center'}} = props;

    useEffect(() => {
        if(addGrocery) setOpen(true);
    }, [addGrocery]);

    const [open, setOpen] = useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <Snackbar open={open} autoHideDuration={disableAutoHideDuration ? null : 2000} onClose={handleClose} anchorOrigin={ anchorOrigin }>
            <Alert severity={severity} icon={false} >
                {text}
            </Alert>
        </Snackbar>
    )
}

export default Toast;