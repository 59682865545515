import React, {forwardRef, useEffect, useState} from 'react';
import ListItem from "@material-ui/core/ListItem";
import SingletoneStoreClass from "../../store/store";
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import SettingsIcon from '@material-ui/icons/Settings';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import './item.scss';
import Modal from "@material-ui/core/Modal";
import AddGroceryModal from './addGroceryModal';
import EditGroceryModal from './editGroceryModal';
import Amount from './amount/amount'

const store = SingletoneStoreClass.getInstance();

const Item = (props) => {
    const CODE_TO_NAME = store.getCodeToName();

    const {id, groceryCode, onDeleteGrocery, categoryName, selectedGroceries, setSelectedGroceries, setGroceries, isSelected, groceries, todoMode, firstRender} = props;
    const [selected, setSelected] = useState(!!selectedGroceries[categoryName][groceryCode]);
    const [newItem, setNewItem] = useState(!firstRender);

    const [openModal, setOpenModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);

    const isTotal = () => categoryName === 'כללי';

    if(newItem){
        setTimeout(()=>setNewItem(false), 1000 * 1)
    }

    const onItemCheck = () => {
        store.updateGrocerySelected({id, category: categoryName, code: groceryCode, selected: !selected});
        setSelectedGroceries({...store.getSelectedGroceries({id})});
        setSelected(!selected);
    };

    useEffect(()=>{
        setSelected(!!selectedGroceries[categoryName][groceryCode])
    },[selectedGroceries]);

    const ModalRef = forwardRef((props, ref) => {
        return  <AddGroceryModal id={id} groceryCode={groceryCode} setOpenModal={setOpenModal} setGroceries={setGroceries}/>;
    });

    const ModalRefEdit = forwardRef((props, ref) => {
        return  <EditGroceryModal id={id} groceryCode={groceryCode} categoryName={categoryName} setOpenEditModal={setOpenEditModal} setGroceries={setGroceries}/>;
    });

    return (
        <ListItem className={`list-item-container ${newItem ? 'new-item' : ''}`} key={`grocery-item-${groceryCode}`}>
            <IconButton edge="end" aria-label="delete" className={'grocery-item-delete'}
                        onClick={() => onDeleteGrocery({category: categoryName, code: groceryCode})}>
                <DeleteIcon/>
            </IconButton>
            <IconButton edge="end" aria-label="edit"
                        onClick={() => setOpenEditModal(true)}>
                <EditIcon/>
            </IconButton>
            {/*{isTotal() ?*/}
            {/*    <IconButton edge="end" aria-label="setting" className={'grocery-item-setting'}*/}
            {/*                onClick={() => setOpenModal(true)}>*/}
            {/*        <SettingsIcon/>*/}
            {/*    </IconButton>*/}
            {/*    : null}*/}
            {todoMode ? null : <Amount id={id} code={groceryCode} categoryName={categoryName} setGroceries={setGroceries} groceries={groceries}/>}

            <ListItemText className={`item-text ${isSelected ? 'is-selected-item' : ''}`}
                          onClick={onItemCheck}
                          primary={CODE_TO_NAME[groceryCode] ? CODE_TO_NAME[groceryCode] : groceryCode}/>
            <Checkbox
                className={'select-check-box'}
                edge="start"
                checked={selected}
                onChange={onItemCheck}
            />
            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
            >
                <ModalRef/>
            </Modal>
            <Modal
                open={openEditModal}
                onClose={() => setOpenEditModal(false)}
            >
                <ModalRefEdit/>
            </Modal>
        </ListItem>
    )
}

export default Item;