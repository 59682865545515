import React, {useEffect, useState} from "react";
import TextField from "@material-ui/core/TextField";
import SingletoneStoreClass from "../../store/store";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import './addGroceryModal.scss'
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        direction: 'ltr'
    }
}));

const store = SingletoneStoreClass.getInstance();

const AddGroceryModal = (props) => {
    const classes = useStyles();


    const CATEGORY_MAP = store.getCategories();
    delete CATEGORY_MAP.GENERAL;
    delete CATEGORY_MAP.DONE;

    const { id, groceryCode, setOpenModal, setGroceries} = props;

    const [groceryName, setGroceryName] = useState(groceryCode);
    const [categoryName, setCategoryName] = useState(null);
    const [aliases, setAliases] = useState(Array.from(Array(7)));
    const [itemClickCounter, setItemClickCounter] = useState({counter: 0, time: null});
    const [showAliases, setShowAliases] = useState( false);

    const onGroceryNameChanged = (value)=>{
        setGroceryName(value.target.value)
    };

    const onSelectCategoryChanged = (event)=>{
        setCategoryName(event.target.value)
    };

    function onAliasChange(event){
        const lastAliases = [...aliases];
        lastAliases[this.index] = event.target.value;
        setAliases(lastAliases);
    }

    const onSendClick = ()=>{
        store.addGroceryManually({id, groceryName, categoryName, aliases});
        setOpenModal(false);
        setGroceries({...store.getGroceries({id})})
    };

    useEffect(() => {
        if (itemClickCounter.counter === 3) {
            const timeDiff = new Date().getTime() - itemClickCounter.time;
            if (timeDiff / 1000 < 2) {
                console.log("clicked 3 times!!!");
                setShowAliases(true);
            }
            setItemClickCounter({counter: 0, time: null});
        }
    }, [itemClickCounter]);

    const onClickOnTitle = ()=>{
        setItemClickCounter({
            counter: itemClickCounter.counter + 1,
            time: itemClickCounter.counter === 0 ? new Date().getTime() : itemClickCounter.time
        });
    }

    return (
        <div className={`add-grocery-manually-modal`}>
            <h2 onClick={onClickOnTitle}>הוספת פריט באופן ידני</h2>
            <TextField label="שם פריט" value={groceryName} onChange={onGroceryNameChanged}/>
            <FormControl className={classes.formControl}>
                <InputLabel id="demo-simple-select-label">בחר קטגוריה</InputLabel>
                <Select
                    value={categoryName || ""}
                    onChange={onSelectCategoryChanged}
                >
                    {Object.keys(CATEGORY_MAP).map(category=>{
                        const categoryName=category;
                        const categoryHebrewName = CATEGORY_MAP[category];
                        return <MenuItem key={`select-category-${categoryName}`} value={categoryName}>{categoryHebrewName}</MenuItem>
                    })}
                </Select>
            </FormControl>

            {showAliases ? aliases.map((a, index)=><TextField key={`select=alias-${index}`} label="alias" onChange={onAliasChange.bind({index})}/>): null}
            <Button className={'modal-send-button'} variant="contained" color="primary" onClick={onSendClick} disabled={!groceryName || !categoryName}>
                הוסף!
            </Button>
        </div>
    )

};

export default AddGroceryModal;